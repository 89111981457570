const applicationId = '72629584-6271-4772-871a-6cff20ca8d75';

export const environment = {
    production: true,
    debug: false,
    apiUrl: '/api',
    pdfUrl: 'https://api.prm.homij.acc.recognize.dev/api',
    logo: '/assets/images/logo_homij.svg',
    applicationInsights: {
        instrumentationKey: 'e7b9c899-4afa-46aa-9484-757eb7e1ab3b'
    },
    msAuth: {
        issuer: 'https://login.microsoftonline.com/common/v2.0',
        clientId: applicationId,
        redirectUri: `${window.location.origin}/msal-auth`,
        scopes: [`${applicationId}/.default`],
        redirectToExternalEndSessionEndpointAfterLogout: false,
        redirectRoute: {
            afterAuthentication: ['/'],
            noAuthentication: ['/no-access'],
            failedAuthentication: ['/error']
        },
        // Use LocalStorage to allow same authentication across multiple browser tabs and persistence of tokens
        sessionStorageDriver: localStorage
    },
    features: {
        accessibleSteps: {
            enabled: true,
            showSetToDefaultButton: false // Useful for testing / debugging
        },
        apiProjectCreation: {
            enabled: false
        }
    }
};
